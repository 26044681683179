<template>
<div id="footer">
  <div>Copyright©{{ thisYear }} Hope Medical. All rights reserved.</div>
</div>
</template>

<script>
export default {
  name: "MFooter",
  data() {
    return {
      thisYear: (new Date()).getFullYear()
    }
  }
}
</script>

<style scoped>
#footer {
  height: 38px;
  background-color: #1796C4;

  font-size: 12px;
  color: #FFFFFF;
  line-height: 38px;
  text-align: center;
}
</style>
