<template>
  <div id="aside">

    <div class="aside" :style="'background-color:'+asideColor+';'">
      <ul>
        <!-- <li><el-link :underline="false" @click="goTo('internal')">胃腸科</el-link></li>
        <li><el-link :underline="false" @click="goTo('kampo')">漢方内科</el-link></li>
        <li><el-link :underline="false" @click="goTo('dermatology')">腫瘍外科</el-link></li> -->
        <li><el-link :underline="false" @click="goTo('generalMedicine')">一般内科</el-link></li>
        <li><el-link :underline="false" @click="goTo('fever')">発熱外来</el-link></li>
        <li><el-link :underline="false" @click="goTo('corona')">コロナ予防接種</el-link></li>
        <li><el-link :underline="false" @click="goTo('diet')">メディカルダイエット</el-link></li>
        <li><el-link :underline="false" @click="goTo('generalMedicine')">プラセンタ</el-link></li>
        <!-- <li><el-link :underline="false" @click="goTo('second')">セカンドオピニオン</el-link></li> -->
        <li><el-link :underline="false" @click="toUrl('ReduceWeight')">ステムサップ</el-link></li>
        <li><el-link :underline="false" @click="goTo('telemedicine')">オンライン診療</el-link></li>
        <!-- <li><el-link :underline="false" @click="goTo('sas')">睡眠時無呼吸症候群</el-link></li>
        <li><el-link :underline="false" @click="goTo('placenta')">プラセンタ</el-link></li>
        <li><el-link :underline="false" @click="goTo('asp')">高山病予防</el-link></li>
        <li><el-link :underline="false" @click="goTo('checkup')">健康診断</el-link></li> -->
      </ul>
    </div>

    <div class="content">
      <div class="title">{{title}}</div>
      <div class="separator" :style="'border-color:'+asideColor+';'">
        <slot></slot>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "DepartmentAside",
  props: {
    asideColor: String,
    title: String
  },
  methods: {
    goTo(path) {
      this.$router.push(`/department/${path}`)
    },
    toUrl(){
      window.open('https://www.hope-tsukiji.com','_self')
    }
  }
}
</script>

<style scoped>
.aside {
  width: 12%;
  height: 350px;
  font-size: 14px;
  position: fixed;
}
.aside ul {
  list-style: none;
  padding-top: 30px;
  padding-left: 20px;
}
.aside ul li {
  margin: 14px 0;
}
.aside li /deep/ a span {
  color: #FFFFFF;
}
.aside li /deep/ a:hover span {
  opacity: 0.8;
}
.content {
  width: 85%;
  margin-left: 15%;
  margin-top: 20px;
  padding-bottom: 20px;
  background-color: #ffffff;
}
.content .title {
  font-size: 30px;
  letter-spacing: 30px;
  padding-top: 20px;
  padding-left: 10%;
}
.content .separator {
  width: 85%;
  margin: 40px auto;
  border-top: 4px solid;
  border-bottom: 4px solid;
}
</style>
