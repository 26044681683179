<template>
<div id="m-top">

  <div class="top">
    <div class="logo">
      <img src="../../assets/logo/hope.png" alt="hope" height="30px">
    </div>
    <div class="clinic-name">のぞみクリニック築地</div>
    <div class="menu" @click="change">
      <img v-if="this.isShowMenu" src="../../assets/m/menu-unfold.svg" alt="" height="30px">
      <img v-else src="../../assets/m/menu.svg" alt="" height="30px">
    </div>
  </div>

  <!-- <div class="menu-list" v-show="this.isShowMenu"> -->
  <nav>
    <ul class="menu-list" id="menu">
      <li class="option" @click="goTo('/')">ホーム</li>
      <li class="option" @click="goTo('/information')">お知らせ</li>
      <li class="sub-menu" id="kannryou">
        <div class="title" @click="changeSub()" >診療一覧</div>
        <div class="sub-menu-arrow" id="kannryou_arrow">▼</div>
        <div class="sub-element" @click="goTo('/department/generalMedicine')">一般内科</div>
        <div class="sub-element" @click="goTo('/department/fever')">発熱外来</div>
        <div class="sub-element" @click="goTo('/department/corona')">コロナ予防接種</div>
        <div class="sub-element" @click="goTo('/department/diet')">メディカルダイエット</div>
        <div class="sub-element" @click="goTo('/department/placenta')">プラセンタ</div>
        <div class="sub-element" @click="goTo('/department/stemsup')">エクソソーム点滴</div>
      </li>
      <li class="option" @click="goTo('/about')">当院について</li>    
      <li class="option" @click="goTo('/department/cancer')">がん治療</li>
      

      <li class="option" @click="goTo('/sending')">お問い合わせ</li>
      <li class="option" @click="goTo('/access')">アクセス</li>
    </ul>
  </nav>
</div>
</template>

<script>
export default {
  name: "MTop",
  data() {
    return {
      isShowMenu: false,
      isShowkannryou:false,
      elementCount:7,
    }
  },
  methods: {
    change() {
      this.isShowMenu = !this.isShowMenu
      var menu_height = this.isShowMenu?this.elementCount * 48:0;
      document.getElementById("menu").style.height = menu_height.toString()+'px';
    },
    changeSub(){
      this.isShowkannryou = !this.isShowkannryou;
      this.elementCount = this.isShowkannryou?13:7;

      var menu_height = this.isShowMenu?this.elementCount * 48:0;
      document.getElementById("menu").style.height = menu_height.toString()+'px'; 

      var kannryou_height = this.isShowkannryou?7*48 : 48;
      document.getElementById("kannryou").style.height = kannryou_height.toString()+'px';

      var arrow_angle = this.isShowkannryou?180 : 0;
      document.getElementById("kannryou_arrow").style.transform = `rotate(${arrow_angle}deg)`;
    },
    goTo(path) {
      this.$router.push(path)
    },
    goHref(path) {
      window.location.href = path;
    }
    
  }
}
</script>

<style scoped>
#m-top {
  padding: 10px 0;
  background-color: #ffffff;
}

.top {
  display: flex;
  justify-content: space-around;
}
.clinic-name {
  line-height: 30px;
  color: #62b6e2;
  font-size: 16px;
  font-weight: bold;
}

.menu-list {
  /*想有个下拉动态，但没成功。*/
  transition: 800ms ease;
  height:0px;
  overflow: hidden;
  font-weight: bold;
}
.menu-list .option {
  line-height: 3rem;
  border-bottom: 1px solid #FFFFFF;
  background-color: #62b6e2;
  color: #edf7fc;
  font-size: 14px;
  padding-left: 1.5rem;
  height: 48px;
  transition: 50ms;
}
.menu-list .option:active {
  background-color: #ffffff;
  color: #62b6e2;
  font-size: 12px;
}
.menu-list .sub-menu{
  line-height: 3rem;
  border-bottom: 1px solid #FFFFFF;
  background-color: #62b6e2;
  color: #edf7fc;
  font-size: 14px;
  padding-left: 1.5rem;
  transition: 800ms ease;
  overflow: hidden;
  height: 48px;
  position: relative;
}
.menu-list .sub-menu .sub-element{
  line-height: 3rem;
  width:80%;
  border-top: 1px solid #FFFFFF;
  background-color: #62b6e2;
  color: #edf7fc;
  font-size: 14px;
  padding-left: 2rem;
  height: 48px;
  transition: 50ms;
}
.menu-list .sub-menu .sub-element:active{
  background-color: #ffffff;
  color: #62b6e2;
  font-size: 12px;
}

.menu-list .sub-menu .sub-title{
  line-height: 3rem;
  height: 48px;
}

.menu-list .sub-menu .sub-menu-arrow{
  transition: 0.8s ease;
  position:absolute;
  top:0px;
  right:40px;
  transform: rotate(0deg);
  font-weight: bold;
  font-size: large;
}

</style>
