<template>
<div id="internal">

  <div class="hidden-sm-and-down">
    <PageHeader/>
  </div>
  <div class="hidden-md-and-up">
    <m-top/>
  </div>

  <div class="hidden-xs-only">
    <el-row type="flex" justify="center">
      <el-col :md="20" :lg="18" :xl="15">
        <div style="width: 100%; max-width: 1284px; margin: 0 auto; min-height:calc(100vh - 160px - 272px);" >
          <div class="side-box" style="margin-bottom: 30px;">
            <div class="title">一般内科</div>
          </div>
          <div class="separator" style="color: #e88383;">





            <div class="content1">
              <div class="side-text">
                <div class="side-box-img">
                  <div style="width: 60%;">
                    <div class="side-box">
                      <div class="side-line-highlight"></div>
                      <div class="title1">一般内科</div>
                    </div>
                    <div class="content_text">
                      <div class="text1">頭痛、めまい、かぜ症状（熱がある、咳がでる、喉が痛むなど）、吐き気、腹痛、下痢などといった急性症状、疲労感や倦怠感、花粉症などの一般的な疾患について診療いたします。また、高血圧や糖尿病、高脂血症（脂質異常症）などの生活習慣病をはじめとする慢性疾患についても幅広く診療いたします。はっきりとした症状が出ているときはもちろん、何科を受診したらいいのかわからないという場合も、ご相談ください。</div>
                      <div class="text1">診療にあたっては、まずは患者さんのお話をよくお聞きした上で、診察、適切な検査を行い、原因を見つけ、個々の患者さん必要な治療を考えていきます。診察の結果、高度な医療や入院が必要と判断されたときは、患者様の症状に適した専門科を判断し、高次医療機関への紹介を行います。</div>
                    </div>
                  </div>
                  <div class="side-img" style="width: 40%; margin-right: 0px;">
                    <img src="../../assets/departments/generalMedicine/naika_01.png" class="fit_img">
                  </div>
                </div>
              </div>
              

            </div>

            <div class="content1">
              <div class="side-text">
                <div class="text1">一般内科は適切な医療を受けるためのとしての最初の窓口としての役割を担っています。地域の皆様の“かかりつけ医”となれるよう、親身で丁寧な初期診療を心がけて参ります。</div>
              </div>
              
            </div>

            <div class="content1">
              <div class="seperate_line"></div>
              <div class="side-text">
                <div class="">主な対応疾患</div>
              </div>
            </div>

            <div class="content1">
              <div class="side-text">
                <div class="side-box">
                  <div class="side-line-highlight"></div>
                  <div class="title1">急性疾患</div>
                </div>
                <div class="content_text">
                  <div class="text1">風邪症候群（熱がある、咳がでる、喉が痛む、鼻水が出る、などが同時に出現するもの）、咽頭炎、扁桃炎、気管支炎、肺炎、インフルエンザ、消化器急性症状（腹痛、下痢、吐き気、胃もたれ感、胸焼け、吐き気）、尿道・膀胱炎（排尿時痛など）など</div>
                </div>
              </div>
            </div>
            <div class="content1" style="margin-bottom: 30px;">
              <div class="side-text">
                <div class="side-box">
                  <div class="side-line-highlight"></div>
                  <div class="title1">慢性疾患</div>
                </div>
                <div class="text1">生活習慣病（高血圧、脂質異常症）、糖尿病、高尿酸血症（痛風）、貧血、便秘症、食欲不振</div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <PageFooter/>
  </div>

  <div class="hidden-sm-and-up">
    <div style="width: 100%; max-width: 1284px; margin: 0 auto;">
          <div class="side-box" style="margin-bottom: 30px;">
            <div class="title">一般内科</div>
          </div>
          <div class="separator" style="color: #e88383;">





            <div class="content1">
              <div class="m_side-text">
                <div>
                  <div>
                    <div class="side-box">
                      <div class="side-line-highlight"></div>
                      <div class="title1">一般内科</div>
                    </div>
                    <div class="content_text">
                      <div class="text1">頭痛、めまい、かぜ症状（熱がある、咳がでる、喉が痛むなど）、吐き気、腹痛、下痢などといった急性症状、疲労感や倦怠感、花粉症などの一般的な疾患について診療いたします。また、高血圧や糖尿病、高脂血症（脂質異常症）などの生活習慣病をはじめとする慢性疾患についても幅広く診療いたします。はっきりとした症状が出ているときはもちろん、何科を受診したらいいのかわからないという場合も、ご相談ください。</div>
                      <div class="text1">診療にあたっては、まずは患者さんのお話をよくお聞きした上で、診察、適切な検査を行い、原因を見つけ、個々の患者さん必要な治療を考えていきます。診察の結果、高度な医療や入院が必要と判断されたときは、患者様の症状に適した専門科を判断し、高次医療機関への紹介を行います。</div>
                    </div>
                  </div>
                  <div class="side-img">
                    <img src="../../assets/departments/generalMedicine/naika_01.png" class="fit_img">
                  </div>
                </div>
              </div>
              

            </div>

            <div class="content1">
              <div class="m_side-text">
                <div class="text1">一般内科は適切な医療を受けるためのとしての最初の窓口としての役割を担っています。地域の皆様の“かかりつけ医”となれるよう、親身で丁寧な初期診療を心がけて参ります。</div>
              </div>
              
            </div>

            <div class="content1">
              <div class="seperate_line"></div>
              <div class="m_side-text">
                <div class="">主な対応疾患</div>
              </div>
            </div>

            <div class="content1">
              <div class="m_side-text">
                <div class="side-box">
                  <div class="side-line-highlight"></div>
                  <div class="title1">急性疾患</div>
                </div>
                <div class="content_text">
                  <div class="text1">風邪症候群（熱がある、咳がでる、喉が痛む、鼻水が出る、などが同時に出現するもの）、咽頭炎、扁桃炎、気管支炎、肺炎、インフルエンザ、消化器急性症状（腹痛、下痢、吐き気、胃もたれ感、胸焼け、吐き気）、尿道・膀胱炎（排尿時痛など）など</div>
                </div>
              </div>
            </div>
            <div class="content1" style="margin-bottom: 30px;">
              <div class="m_side-text">
                <div class="side-box">
                  <div class="side-line-highlight"></div>
                  <div class="title1">慢性疾患</div>
                </div>
                <div class="text1">生活習慣病（高血圧、脂質異常症）、糖尿病、高尿酸血症（痛風）、貧血、便秘症、食欲不振</div>
              </div>
            </div>
          </div>
        </div>


    <m-departs/>
    <m-footer/>
  </div>
</div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import DepartmentAside from "@/components/DepartmentAside";
import MTop from "../../components/m/MTop";
import MFooter from "../../components/m/MFooter";
import MDeparts from "../../components/m/MDeparts";
export default {
  name: "Internal",
  components: {MDeparts, MFooter, MTop, DepartmentAside, PageFooter, PageHeader}
}
</script>

<style scoped>
/* PC */
.hidden-sm-and-down .img {
  margin-top: 30px;
}
.detail1 {
  margin: 40px 20px;
}
.content1 {
      margin-top: 40px;
    }

    .content_text{
        margin-top: 18px;
    }
.title1 {
      color: #e88383;
      font-size: 18px;
      margin-bottom: 10px;
    }

    .text1 {
      color: #535353;
      line-height: 1.5;
      margin-bottom: 15px;
    }
.other {
  color: #535353;
  font-size: 14px;
  line-height: 1.5;
}

.seperate_line{
      height:5px;
      border-top-color: #e88383;
      border-top-width: 2px;
      border-top-style: solid;
      margin: 0 auto;
      width: 100%;
    }

/* SP */
.m-main {
  background-color: #FFFFFF;
  border-top: 2px solid #e88383;
  border-bottom: 2px solid #e88383;
  padding: 2rem 0;
}
.m-title {
  color: #535353;
  font-size: 24px;
  text-align: center;
  margin: 2rem 0;
}
.m-detail {
  background-color: #FFFFFF;
  padding: 0 1rem;
}
.m-subtitle {
  color: #eb97ae;
  font-size: 18px;
  padding: 2rem 0 1rem;
}
.m-content {
  line-height: 24px;
  color: #535353;
}
.title {
      font-size: 30px;
      letter-spacing: 5px;
      padding-top: 20px;
      padding-left: 20px;
    }
    .separator {
      width: 100%;
      margin: 0 auto;
      border-top: 4px solid;
      border-bottom: 4px solid;  
    }

.img-side{
height: fit-content;
width:fit-content;
}
.img-side-text{
height: 100%;


}
.img-side-box{
  display: inline-flex;
  justify-content: space-between;
  align-items:left;
  vertical-align: center;
  height: fit-content;
}

.side-img{
    height: auto;
    width:auto;
    vertical-align:middle;
    margin-left: 30px;
    margin-right: 30px;
    }
  
    .side-text{
    height: fit-content;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: auto;
    }
    .m_side-text{
    height: fit-content;
    margin-left: 7%;
    margin-right: 7%;
    width: 86%;
    margin-bottom: auto;
    }
    
    
    .side-box{
      display: inline-flex;
      justify-content: space-between;
      align-items:center;
      vertical-align: top;
      height: auto;
    }
  
    .side-box-img{
      display: inline-flex;
      justify-content: space-between;
      align-items:top;
      vertical-align: top;
      height: auto;
    }
  
    .fit_img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    .side-line-highlight{
      background-color: #eb97ae;
      width:6px;
      height: 25px;
      margin-bottom: 10px;
      margin-right: 10px;
    }
</style>
